.w-40
{
    width: 40%;
}
.w-45
{
    width: 45%;
}
.w-55
{
    width: 55%;
}
.w-60
{
    width: 60%;
}
.w-65
{
    width: 65%;
}
.w-70
{
    width: 70%;
}
.w-75
{
    width: 75%;
}
.w-80
{
    width: 80%;
}
.w-90
{
    width: 90%;
}