#expanding{
    .dotted-border{
        border: 1px dashed #AAB7C3;
        border-radius: 15px;
        opacity: 1;
        padding: 50px;
        min-height: 95%;
        font: normal normal normal 15px Nunito;
        text-align: center;
        p{
            letter-spacing: 0px;
            line-height: 2;
            color: #0A194E;
            opacity: 1;
        }

        .socials{
            max-width: 70%; 
            font-size: 20px;
            a:hover{
                text-decoration: none;
            }
        }
    }

    .card{
        
    }
}