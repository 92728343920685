#locations{
    background: $light-blue url("../../images/backgrounds/contact-bg.png") center no-repeat;
    text-align: center;
    color: $white !important;
    min-height: 100vh;
    color: $white;
    padding-top: 150px;
    .card{
        min-height: 190px;
    }
    .main-p{
        font: normal normal normal 22px Nunito;
    }
    .vh-50{
        // min-height: 50vh;
    }
    .directions{
        margin-bottom: 0;
        width: max-content;
        cursor: pointer;
    }
    .directions:hover ~ .styled-hr{
        width: 120px
    }
    .styled-hr{
        border-bottom: 2px solid $primary;
        margin: 0;
        width: 80px;
        transition: width .9s;
    }
}

#available{ 
    .social-icons{
        padding: 30px 20px;
        color: $deep-blue;
        border-bottom: 3px solid transparent;
        &:hover{
            border: 0.1px solid #0D5FB6;
            color: #0D5FB6;
            border-radius: 10px;
            background-color: $light-blue;
            &i:before{
                color: #0D5FB6;
            }
        }
        
    }
    .call-icon{
        color: black;
        text-decoration: none;
    }
}

#contact{
    background: #F7F7F7 0% 0% no-repeat padding-box;
    .form-group{
        label{
            text-align: center;
            font: normal normal normal 12px Montserrat;
            letter-spacing: 0px;
            color: #203442;
            opacity: 1;
        }
    }
    .form-control{
        border: 1px solid #D6D6D6 !important;

        &:focus{
            outline: none;
            // border-color: $light-blue;
            box-shadow: none;
        }
    }
}