#inclusive{
    background: $light-blue url("../../images/backgrounds/inclusive-bg.png") right no-repeat;
    background-size: 40%;
    min-height: 92vh;
    .row{
        margin-top: 40px;
    }
    color: $deep-blue !important;

    .main-header{
        font: normal normal 600 50px Nunito;
        letter-spacing: 0px;
        opacity: 1;
    }
    p{
        width: 50%;
    }

    @include media-breakpoint-down(sm) {
        background: $light-blue url("../../images/backgrounds/inclusive-bg.png") center no-repeat;
        text-align: center;
        color: $white !important;
        p{
            width: 100%;
        }
      }
}

p{
    line-height: 30px;
}