#blog{
    img{
        border-radius: 15px;
    }
    .bottom {
        position: absolute;
        padding: 0 1rem;
        bottom: 0;
        color: $white !important;

        .readmore{
            position: relative;
            color: $white !important;
            text-decoration: none;
            cursor: pointer;
        }
        .readmore::after {
            content: "";
            display: block;
            width: 30%;
        }

        .styled-hr{
            width: 40px;
            margin: 0;
            background: $white;
            transition: width .9s;
            margin-bottom: 5px;
            padding: 1px;
        }
        .readmore:hover ~ .styled-hr{
            width: 70px;

        }

    }
    @include media-breakpoint-down(sm) {
        text-align: center;
        color: $white !important;
        .readmore{
            width: 100%;
        }
        .readmore::after{
            content: '';
            display: block;
            margin: 0 35%;
        }
      }
    
}