.btn{
    box-shadow: 0px 8px 16px #00000029;
    border-radius: 8px;
    font-size: small;
    font-size: 16px !important;
    font-weight: 100 !important;
}

.btn-outline-primary{
    -webkit-transition-duration: 0.8s !important;
    -webkit-transition-timing-function: linear !important;
    box-shadow:0px 0 0 $primary  inset !important;
    &:hover{
        box-shadow:150px 0 0 $primary inset !important;
        background-color: $white !important;
       
    }
}