// Theme colors
$blue: #1a2ad1;
$deep-blue: #0a194e;
$light-blue: #F5F9FF;
$white:    #fff;
$black: #000;
$light-gray: #F6F6F6;

$primary:       $blue;

// CUSTOM THEME
$theme-colors: (
  "deep-blue": $deep-blue,
  "light-blue": $light-blue,
  "light-gray": $light-gray,
);


// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;
