.navbar{
        padding-top: 10px !important;
        box-shadow: 0px 4px 8px #0000000A;
        // max-width: 1140px;
        // margin: auto;
        padding-bottom: 0px !important;
        .nav-item{
            margin-left: 15px;
            margin-right: 15px;
            text-align: center;
            letter-spacing: 0px;
            padding: 10px;
            opacity: 1;
            &:hover{
                background-color: #F5F9FF;
            }
        }
        .nav-link{
            color: $deep-blue !important;
            font: normal normal 600 13px Nunito;
        }
        .active{
            .nav-link{
                color: $primary !important;
            }
            border-bottom: 2px solid $primary ;
        }

        // .dropdown-menu{
        //     display: flex;
        // }

        .dropdown-item{
            border-right: 1px solid $light-blue;
            min-width: 70px;
            font-size: 12px;
            padding-left: 35px;
            padding-right: 35px;
            color: $deep-blue;
        }
}
