#trackrecord{
    .track-card{
        background: #FFB03C 0% 0% no-repeat padding-box;
        box-shadow: 0px 16px 12px #00000029;
        opacity: 1;
        padding: 20px;
        color: #203442;

    }
    .nav{
        display: block;
        width: 25%;
        .nav-link{
            border: none !important;
            color: $deep-blue !important;
            &:hover{
                cursor: pointer;
            }
            .styled-hr{
                width: 60%;
                display: none;
                padding: 1px;
            }
        }

        .active{
            color: $primary !important;
            border-bottom-style: 2px solid $primary !important;
            border-bottom-width: 70%;
            .styled-hr{
                display: block;
                background-color: $primary;
            }
        }
    }

    .nav-tabs{
        border: none !important;
        border-left: 4px solid #F6F9FE !important;

    }
    .react-multiple-carousel__arrow {
        background: #FFFFFF00 0% 0% no-repeat padding-box !important;
        border: 1px solid $primary !important;
        color:  $primary !important;
        opacity: 1;
        &:before{
            color:  $primary !important;            
        }
    }
    .react-multiple-carousel__arrow--left{
        display: none !important;
    }
}