#footer{
    color: $deep-blue;
    .lists{
        padding: 0;
        list-style: none;

    }

    .lists li {
        padding: 0.3rem 0;
        font-size: 12px;
    }

    a{
        color: $deep-blue;
        text-decoration: none;
    }
}